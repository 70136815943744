<template>
  <div class="about-container">
    <div class="banner">
      <div class="banner-div w">
        <div class="banner-title">{{ beian.name }}</div>
        <div class="banner-content">
          打造本地生活权益矩阵，高效推动平台数字化营销
        </div>
      </div>
    </div>
    <div class="nav w">
      <ul class="nav-ul success-list">
        <li class="nav-li">
          <p class="nav-p1">
            <span class="scroll-num num0">200</span><span>+</span>
          </p>
          <p class="nav-p2">品牌运营</p>
          <span class="nav-strip"></span>
        </li>
        <li class="nav-li">
          <p class="nav-p1">
            <span class="scroll-num num1">10</span><span>+</span>
          </p>
          <p class="nav-p2">合作客户</p>
          <span class="nav-strip"></span>
        </li>
        <li class="nav-li">
          <p class="nav-p1">
            <span class="scroll-num num2">1.2</span><span>亿+</span>
          </p>
          <p class="nav-p2">用户覆盖</p>
          <span class="nav-strip"></span>
        </li>
        <li class="nav-li">
          <p class="nav-p1">
            <span class="scroll-num num3">10</span><span>+</span>
          </p>
          <p class="nav-p2">服务行业</p>
        </li>
      </ul>
    </div>
    <div class="info w">
      <div class="img">
        <img src="../assets/aboutUs-building.png" alt="" />
      </div>
      <div class="con">
        <div class="tit">关于{{ beian.name }}</div>
        <div class="blue"></div>
        <div class="txt">
          <div>
            <span style="color: #3e68ff">{{ beian.name }}</span>
            ，创立于2017年，是国内专业的数字权益商城解决方案服务商，专注会员权益及流量运营领域，积极布局数字会员权益生态，覆盖APP、公众号、小程序、H5等平台，高效匹配连接品牌、商家和用户三方诉求，致力于打造全链路营销体系。
          </div>
          <div>
            {{
              beian.name
            }}权益产品链，价值赋能合作企业，深度服务用户，打造会员制商城服务，高效促进用户的引流、转化、留存及变现，助力构建公私域营销闭环。
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import checkHost from "../../public/host";
export default {
  name: "about",
  data() {
    return {
      nowPath: "",
      beian: {},
    };
  },
  created() {
    // const beian = checkHost(window.location.host);
    const beian = localStorage.getItem("beian");
    this.beian = JSON.parse(beian);
  },
  watch: {
    $route: {
      handler: function (val) {
        var that = this;
        that.nowPath = val.fullPath;
        window.scrollTo(0, 0);
      },
      // 深度观察监听
      deep: true,
    },
  },
};
</script>

<style lang="less" scoped>
.about-container {
  .banner {
    height: 250px;
    background: url(../assets/aboutUs-banner.png) no-repeat center;

    .banner-div {
      padding-top: 62px;
      .banner-title {
        font-size: 40px;
        line-height: 22px;
        color: #333333;
      }

      .banner-content {
        font-size: 17px;
        line-height: 22px;
        color: #555555;
        margin-top: 19px;
      }
    }
  }

  .nav {
    margin: -62px auto 0;
    box-shadow: 0 2px 10px 0 rgba(51, 51, 51, 0.1);
    border-radius: 10px;
    background-color: #fff;

    .nav-ul {
      margin: 0 auto;
      display: flex;
      height: 150px;

      .nav-li {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .nav-p1 {
          font-size: 34px;
          color: #275aff;
        }
        .nav-p2 {
          font-size: 18px;
          color: #333;
        }
      }
    }
  }

  .info {
    margin: 100px auto;
    display: flex;
    justify-content: space-between;

    .img {
      margin-right: 20px;
      width: 460px;
      height: 340px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .con {
      width: 652px;
      .tit {
        font-size: 30px;
        line-height: 22px;
        color: #333333;
      }

      .blue {
        width: 69px;
        height: 3px;
        background-color: #3e68ff;
        margin: 19px 0 27px 0;
      }

      .txt {
        font-size: 17px;
        line-height: 30px;
        color: #666666;
      }
    }
  }
}
</style>
